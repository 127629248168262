import { template as template_d49b83a44c0d45359ddaae21d33a5283 } from "@ember/template-compiler";
const FKText = template_d49b83a44c0d45359ddaae21d33a5283(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
