import { template as template_e47d730dc4c04670be44f522864f2ede } from "@ember/template-compiler";
const FKControlMenuContainer = template_e47d730dc4c04670be44f522864f2ede(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
